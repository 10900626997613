.App {
  position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    font-family: var(--regular-font);
    min-height: 100vh;
  }

  .content-wrapper{
    flex:1;
    width: 100%;
    display: flex;
    justify-content: center;
  }