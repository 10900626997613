.end-text{
    font-size: 2em;
    font-family: var(--special-font);
}
.text {
    font-size: 1.5em;
    line-height: 1.5;
    text-align: left;
    white-space: pre-line;
}
.text::first-letter{
    font-size:1.7em;
    font-weight: bold;
}

.story-input-container{
    font-size: 1.25em;
    text-align: right;
    font-style: italic;
    
}
.option-line{
    margin-top: 10px;
}
.story-button-action{
    margin-top: 20px;
    text-align: right;
}

.option-label{
    padding-right: 5px;
    font-weight: 600;
}

.story-options-button{
    padding: 5px; 
    margin-top: 15px; 
    font-family: var(--special-font);

}
.story-end{
    text-align:center;
    margin-bottom: 50px;
}

